.container {
    --font-size: 1.125rem;
    --disabled-color: #999;
    --space: 0.75rem;

    display: flex;
    border: #4986a1 1px solid;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
}

.input {
    flex-grow: 1;
    border: 0;
    outline: 0;
    font-size: var(--font-size);
    padding: 0;
}

.info {
    color: var(--disabled-color);
    font-weight: normal;
    margin-right: var(--space);
    font-size: var(--font-size);
}

.bar {
    border-right: var(--disabled-color) 1px solid;
    margin-right: var(--space);
}

.button {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin-right: var(--space);
    display: flex;
    align-items: center;

    .icon {
        height: calc(var(--font-size) * .75);
        color: #666;
    }

    &.active {
        .icon {
            color: red;
        }
    }
}