.left {
    text-align: center;
    font-weight: 500;
    padding: 0.625rem;
    background-color: white;
    border-bottom: 1px solid #979797;
    box-shadow: 0 -1px 0.5rem 0 #00000085;
    z-index: 1;
}

.offlineHint {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.25rem;
    background-color: #e28b59;
    min-height: 1rem;
}