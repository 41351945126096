.tag {
    --bg: #aaa;
    --height: 1.625rem;

    position: relative;
    display: inline-block;
    border: 0;
    background-color: var(--bg);
    color: black;
    padding: 0 0.75rem;
    margin-right: 1.25rem;
    margin-top: 0.5rem;
    line-height: var(--height);
    cursor: pointer;
    outline: 0;
    font-weight: 500;
    font-size: 1.125rem;

    &:hover {
        --bg: #ccc;
    }

    &.selected {
        --bg: #548eaa;
        color: white;
    }

    &::after {
        --width: 0.5rem;

        position: absolute;
        display: block;
        content: '';
        width: 0;
        height: 0;
        right: calc(var(--width) * -1);
        top: 0;
        border: calc(var(--height) / 2) solid transparent;
        border-left-width: var(--width);
        border-right-width: 0;
        border-left-color: var(--bg);
    }
}