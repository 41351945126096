.action {
    --size: 4rem;
    --space: 1.25rem;

    position: absolute;
    bottom: 2rem;
    right: 2rem;
    background-color: #65a3be;
    color: white;
    border: 0;
    padding: var(--space);
    cursor: pointer;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    box-shadow: 0.125rem 0.25rem 0.75rem 0 #00000085;
    outline: 0;

    &:hover:not([disabled]) {
        background-color: #4986a1;
    }

    &.active::after {
        --size: 0.5rem;

        position: absolute;
        display: block;
        content: '';
        width: var(--size);
        height: var(--size);
        background-color: red;
        border-radius: 50%;
        top: calc(var(--space) - var(--size) / 2);
        right: calc(var(--space) - var(--size) / 2);
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    &.cancel {
        background-color: #0005;
        box-shadow: none;
        padding-top: 1rem;
 
        &:hover {
            background-color: #0009;
        }
   }
}