.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.left {
    text-align: center;
    font-weight: 500;
    padding: 0.625rem;
    background-color: #ddd;
}

.actions {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0;
}

.form {
    display: flex;
    flex-direction: column;
}

.action {
    --radius: -7rem;
    --offset: -5rem;
    transition: all 0.15s ease-in-out;

    &.hidden {
        opacity: 0;
        box-shadow: none;
        z-index: -1;
    }

    &.add {
        transform: translateX(var(--radius));
    }

    &.refresh {
        transform: translateY(var(--radius));
    }

    &.offline {
        transform: translateX(var(--offset)) translateY(var(--offset));
    }
}
