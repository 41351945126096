.article {
    padding: 3rem;
    flex-grow: 1;
    overflow: auto;
}

.link {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.5rem;

    &:hover {
        color: #548eaa;
    }
}

.actions {
    display: flex;
    justify-content: stretch;
    margin: 1rem 0;
}

.action {
    flex-grow: 1;
    margin-left: 1rem;

    &:first-child {
        margin-left: 0;
    }
}

.tags {
    margin: 1rem 0;
}
