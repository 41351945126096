.container {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    min-height: 100%;
    max-height: 100%;
    z-index: 2;
}

.window {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: block;
    margin: 1rem 1rem;
}

.action {
    float: right;
}

.content {
    flex: 1 1 0;
    overflow: auto;
    padding: 0 1rem;

    img {
        width: 180px;
        height: 50px;
        background-color: #3db6e6;
    }
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.75rem 1.5rem;

    * {
        flex-grow: 1;
        margin: 0 0.25rem;
    }
}