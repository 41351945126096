.button {
    margin: 0.5rem 0;
    background-color: #65a3be;
    color: white;
    font-weight: 500;
    border: 0;
    padding: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    vertical-align: middle;

    &:hover:not([disabled]) {
        background-color: #4986a1;
    }

    svg {
        margin: 0 0.5rem -0.125rem;
        height: 1em;
    }

    &.withIcon {
        padding-left: 0.5rem;
    }

    &.small {
        padding: 0.75rem 1rem 0.5rem;
        font-size: 1rem;
        line-height: 1rem;

        .text {
            display: block;
            font-size: .75rem;
        }
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }
}
