.processing {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        display: block;
        text-transform: uppercase;
        font-weight: 500;
        color: #999;
        font-size: 1.5rem;
    }
}
