.actions {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0;
}

.all {
    color: #aaa;
    height: 1.25rem;
    cursor: pointer;
}

.selected {
    color: #65a3be;
}

.form {
    display: flex;
    flex-direction: column;
}