.article {
    --space: 0.5rem;

    padding: 1rem;
    background-color: white;
    margin-bottom: var(--space);

    &:last-child {
        --space: 0;
    }
}

.link {
    // color: #487284;
    color: #333;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;

    &:hover {
        color: #548eaa;
    }
}

.actions {
    display: block;
}

.action {
    margin-right: 0.75rem;

    &:last-child {
        margin-right: 0;
    }
}