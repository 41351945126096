.article {
    --space: 0.5rem;

    padding: 1rem;
    background-color: white;
    margin-bottom: var(--space);

    &:last-child {
        --space: 0;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.link {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;

    &:hover {
        color: #548eaa;
    }
}

.tags {
    margin: 0.5rem 0;
}

.right {
    float: right;
}

.action {
    margin-right: 0.75rem;

    &:last-child {
        margin-right: 0;
    }
}
