.input {
    --margin-v: 0.5rem;

    margin: calc(var(--margin-v) + 1px) 0;
    padding: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    outline: 0;
    border-radius: 0.25rem;

    &, &:focus {
        border: #4986a1 1px solid;
    }

    &:focus {
        border-width: 2px;
        margin: var(--margin-v) 0;
    }
}