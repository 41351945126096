.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ddd;
}

.list {
    overflow: auto;
    flex-grow: 1;
}

.actions {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0;
}
