.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
}

.body {
    position: relative;
    flex: 1 1 0;
    overflow: hidden;
}

.menu {
    display: flex;
    justify-content: stretch;
    border-top: 1px solid #979797;
    box-shadow: 0 -1px 0.5rem 0 #00000085;
    padding-bottom: env(safe-area-inset-bottom, 1.5rem);
    z-index: 1;
}

.tab {
    display: block;
    flex-grow: 1;
    height: 4rem;
    border: 0;
    margin: 0;
    background-color: transparent;
    outline: 0;
}

.icon {
    box-sizing: border-box;
    height: 100%;
    padding: 1rem 0;
    color: #979797;
    cursor: pointer;

    &.active {
        color: #65a3be;
    }
}
